<template>
  <div>
    <div
        style="min-height:600px;"
        data-key="04AxJXeSBFk4qtBbhQ9JCK1987mtnF"
        id="store-finder-widget"
    ></div>
  </div>
</template>

<script>
export default {
  mounted() {
      const script = document.createElement("script");
      script.src = "https://locator.uberall.com/locator-assets/storeFinderWidget-v2.js";
      script.type = "text/javascript";
      document.body.appendChild(script);

      script.onload = () => {
        window.document.dispatchEvent(new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true
        }));
      };
  },
};
</script>