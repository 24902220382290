<template>
  <div class="mt-11">
    <H1Intro :title="text.h1intro.title" :text="text.h1intro.text" :passed-class="'mt-16'" v-if="text.h1intro"/>
    <HeroContent :title="text.heroContent.title"
                 :text="text.heroContent.text"
                 position="right"
                 :passed-class="'pb-12 mb-16 pt-3'"
                 :image="heroImage"
                 v-if="text.heroContent"/>
    <TwoColorDividers upper-color="#FFBC0D" lower-color="#1F6437" passed-class="mt-16"/>
    <ConversionTeaserBig background-color="#d90007"
                         :button-text="text.conversionTeaserBig.button"
                         :title="text.conversionTeaserBig.title"
                         :text="text.conversionTeaserBig.text"
                         :link="text.conversionTeaserBig.link"
                         v-if="text.conversionTeaserBig"
    />
    <ParagraphImageVertical :image="verticalImage" passed-class="mb-3"/>
    <TwoColorDividers upper-color="#1F6437" lower-color="#FFBC0D"/>
    <ParagraphImageHorizontal :title="text.paragraphImageHorizontal1.title"
                              :text="text.paragraphImageHorizontal1.text"
                              :image="horizontalImage"
                              :passed-class="'pl-16 pr-16 mt-7 mb-16 pb-12'"
                              v-if="text.paragraphImageHorizontal1"
    />
    <Gallery :path="text.gallery.path" :no-of-images="text.gallery.noOfImages" background="#A9C141"
             v-if="text.gallery && text.gallery.path && text.gallery.noOfImages" :passed-class="'mt-16'"
             :title="text.gallery.title" :captions="text.gallery.captions"/>
    <TwoColorDividers upper-color="#D90007" lower-color="transparent"/>
    <iframe src="https://www.md-bilddatenbank.de/start/images/Panorama/0132_MUC_Tal_RE/" width="100%" height="600"/>
    <HorizontalCardModule bg-color="#ffbc0d" passed-class="mt-1 pt-16 pb-16" :data="text.horizontalCardModule"/>
    <DoubleCardsModule bg-color="#1f6437" :images="doubleCardImages" :data="text.doubleCardsModule"
                       passed-class="mt-4 pt-16 pb-16" v-if="text.doubleCardsModule"/>
    <TwoColorDividers upper-color="#A9C141" lower-color="#D90007" passed-class="mb-10"/>
  </div>
</template>

<script>
import heroImage from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_Instore_Big_Teaser_01.jpg";
import verticalImage from "@/assets/img/3_McDrive_Instore_FutureConcepts/McD_Instore_Fullscreenimages_01.jpg";
import horizontalImage from "@/assets/img/3_McDrive_Instore_FutureConcepts/McD_McDrive_Content_images_02.jpg";
import doubleCardImage1 from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_McDrive_Half_Teaser_01.jpg";
import doubleCardImage2 from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_FutureConcepts_Half_Teaser_01.jpg";

import H1Intro from "@/components/common/H1Intro";
import HeroContent from "@/components/common/HeroContent";
import TwoColorDividers from "@/components/common/TwoColorDividers";
import ConversionTeaserBig from "@/components/common/ConversionTeaserBig";
import ParagraphImageVertical from "@/components/common/ParagraphImageVertical";
import ParagraphImageHorizontal from "@/components/common/ParagraphImageHorizontal";
import HorizontalCardModule from "@/components/HorizontalCardModule";
import DoubleCardsModule from "@/components/DoubleCardsModule";
import Gallery from "@/components/common/Gallery";

export default {
  name: "InstorePage",
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  components: {
    Gallery,
    DoubleCardsModule,
    HorizontalCardModule,
    ParagraphImageHorizontal,
    ParagraphImageVertical, ConversionTeaserBig, TwoColorDividers, HeroContent, H1Intro
  },
  data: () => ({
    commonPadding: 'pt-16 pb-16',
    topPadding: 'pt-16',
    bottomPatting: 'pb-16',
    heroImage,
    verticalImage,
    horizontalImage,
    doubleCardImages: [
      doubleCardImage1,
      doubleCardImage2
    ]
  }),
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  computed: {
    text() {
      return this.$store.state.text.instore;
    },
  }
}
</script>

<style scoped>

</style>