
// Generic method to get the value of a given cookie
function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

// Generic method to re-create / override a cookie and its value
function overrideCookie(name,value,days) {
    let expires;
    let domain = '';

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 *1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/;' + domain;
}

// Specifically over-riding the OptanonConsent cookie after identifying which categories to turn on and off
function overrideOptanon(all_ids, categories) {
    let categories_list = categories.split(',');
    let all_ids_list = all_ids.split(',');
    let cookievalue = getCookie('OptanonConsent');

    // Looping through the active categories and toggling them on
    for (let id of categories_list) {
        cookievalue = cookievalue.replace(id + ':0', id + ':1');
    }

    // Comparing new and old consent to identify which categories to turn off
    for (let ref_id of all_ids_list) {
        if (categories.indexOf(',' + ref_id + ',') < 0) {
            // ID no longer active, consent to be withdrawn, setting it to false / :0
            cookievalue = cookievalue.replace(ref_id + ':1', ref_id + ':0');
        }
    }

    // Over-riding the OptanonConsent cookie
    overrideCookie('OptanonConsent', cookievalue, 365);
    // Debugging / Logs:
    // document.getElementById("datalayer").innerHTML = "Data Layer OnetrustActiveGroups Value:<br />" + OnetrustActiveGroups;
    // document.getElementById("cookievalue").innerHTML = "Cookie Value:<br />" + cookievalue;
}


export default function addActiveGroups(category) {
    // Listing all the IDs existing by default in order to be able to flag which ones would be turned off
    let all_ids = '1,2,3,4,6,7';
    // Updating OnetrustActiveGroups dataLayer variable with IDs of the active groups
    if (window.OnetrustActiveGroups.indexOf(',' + category) < 0) {
        window.OnetrustActiveGroups = window.OnetrustActiveGroups + category + ',';
        window.dataLayer.push({ OnetrustActiveGroups: window.OnetrustActiveGroups});
        // Override the OptanonConsent cookies by toggling on and off the various categories
        overrideOptanon(all_ids, window.OnetrustActiveGroups);
        // Push the API calls after updating the status of the various categories
        // consentReceiptCall(all_ids, window.OnetrustActiveGroups);
        // Update page state
        window.OptanonWrapper();

        // Update toggle in preferences layer
        let toggle = document.getElementById('ot-group-id-' + category)
        toggle.checked = true;
        toggle.setAttribute('aria-checked', 'true');
    }
}
