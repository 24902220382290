<template>
  <div>
    <H1Intro :title="standorte.h1intro.title" :text="standorte.h1intro.text" :passed-class="'mt-16 pt-13'"/>
    <HeroContent :title="standorte.heroContent.title"
                 :text="standorte.heroContent.text"
                 :image="heroImage"
                 position="right"
                 passed-class="pb-16 pt-0 mt-8"
    />
    <TwoColorDividers upper-color="#FFBC0D" lower-color="#D90007" :switched="true" passed-class="mt-9"/>
    <ParagraphImageHorizontal :title="standorte.paragraphImageHorizontal.title"
                              :text="standorte.paragraphImageHorizontal.text"
                              passed-class="pl-16 pr-16 mb-0 pt-5"/>
    <ParagraphImageVertical :image="verticalimage"/>
    <ThreeStamps :stamp1="stamp1" :stamp2="stamp2" :stamp3="stamp3" :title="standorte.threeStamps.title"
                 :button="standorte.threeStamps.button" :link="standorte.threeStamps.link" class="mb-16 mt-16 pb-4 "
                 passedClass="pl-5 pr-5"/>
    <ConversionTeaserBig background-color="#d90007"
                         :button-text="standorte.conversionTeaserBig.button"
                         :title="standorte.conversionTeaserBig.title"
                         :text="standorte.conversionTeaserBig.text"
                         :link="standorte.conversionTeaserBig.link"
    />
    <TwoColorDividers upper-color="#1F6437" lower-color="#FFBC0D"/>
    <ParagraphImageHorizontal :title="standorte.paragraphImageHorizontal2.title"
                              :text="standorte.paragraphImageHorizontal2.text"
                              :image="imageHorizontal2"
                              passed-class="pa-16 pt-6 mb-9"/>
    <TwoColorDividers upper-color="#D90007" lower-color="#FFBC0D"/>
    <DynamicCards :cards-data="standorte.threeCards" :images="cardsImages" :border="true"
                  passed-class="pt-16 mt-10 pb-14" height="450"
                  passed-text-class="dynamicCards1"/>
    <ActualMap/>
    <HorizontalCardModule bg-color="#ffbc0d" passed-class="mt-14 pt-16 pb-16" :data="standorte.horizontalCardModule1"/>
    <TwoColorDividers lower-color="#D90007" upper-color="#A9C141" passed-class="pb-10"/>
  </div>
</template>

<script>
import heroImage from "@/assets/img/2_Standorte/MCD_Standorte_Big_Teaser_01.jpg";
import verticalimage from '@/assets/img/2_Standorte/McD_Standorte_Fullscreenimages_01.jpg';
import ConversionTeaserBig from "../common/ConversionTeaserBig";
import imageHorizontal2 from '@/assets/img/2_Standorte/McD_Standorte_Content_images_01.jpg';
import card1 from "@/assets/img/0_Global/MCD_McDrive_Third_Teaser_01.jpg";
import card2 from "@/assets/img/0_Global/MCD_Instore_Third_Teaser_01.jpg";
import card3 from "@/assets/img/0_Global/MCD_FutureConcepts_Third_Teaser_01.jpg";
import stamp1 from '@/assets/img/1_Home/Infographik/Mitarbeiter_2023.svg';
import stamp2 from '@/assets/img/1_Home/Infographik/Franchise_2023.svg';
import stamp3 from '@/assets/img/1_Home/Infographik/Restaurants_2023.svg';


import H1Intro from "../common/H1Intro";
import HeroContent from "../common/HeroContent";
import ParagraphImageHorizontal from "../common/ParagraphImageHorizontal";
import TwoColorDividers from "../common/TwoColorDividers";
import ParagraphImageVertical from "../common/ParagraphImageVertical";
import ThreeStamps from "../common/ThreeStamps";
import DynamicCards from "../common/DynamicCards";
import HorizontalCardModule from "../HorizontalCardModule";
import ActualMap from "../common/ActualMap";

export default {
  name: "StandortePage",
  metaInfo: function () {
    return {
      title: this.standorte.metaTitle ? this.standorte.metaTitle : '',
      meta: [
        {name: 'description', content: this.standorte.metaDescription ? this.standorte.metaDescription : ''}
      ]
    }
  },
  components: {
    ActualMap,
    HorizontalCardModule,
    DynamicCards,
    ConversionTeaserBig,
    ThreeStamps, ParagraphImageVertical, TwoColorDividers, ParagraphImageHorizontal, HeroContent, H1Intro
  },
  data: () => ({
    heroImage,
    verticalimage,
    stamp1,
    stamp2,
    stamp3,
    imageHorizontal2,
    cardsImages: [
      card1,
      card2,
      card3
    ],
  }),
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  computed: {
    standorte() {
      return this.$store.state.text.standorte;
    },
  }
}
</script>

<style scoped>

</style>
