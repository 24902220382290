<template>
  <v-container fluid class="pa-0 pt-16 mt-12">
    <H1Intro v-if="$store.state.text.modules.h1intro"
             :title="$store.state.text.modules.h1intro.title" :text="$store.state.text.modules.h1intro.text"/>
    <HeroContent :title="$store.state.text.modules.heroContent.title"
                 :text="$store.state.text.modules.heroContent.text"
                 position="left"
                 passed-class="pb-1 pt-4"
                 v-if="$store.state.text.modules.heroContent"
    />
    <TwoColorDividers lower-color="#1f6437" upper-color="#ffbc0d"
                      :passed-class="!$vuetify.breakpoint.xs ? 'mt-16 pt-11' : ''"/>
    <ConversionTeaserBig background-color="#d90007" v-if="$store.state.text.modules.conversionTeaserBig"
                         :button-text="$store.state.text.modules.conversionTeaserBig.button"
                         :title="$store.state.text.modules.conversionTeaserBig.title"
                         :text="$store.state.text.modules.conversionTeaserBig.text"
                         :link="$store.state.text.modules.conversionTeaserBig.link"
    />
    <ParagraphImageVertical :image="paragraphImage" v-if="paragraphImage"/>
    <TwoColorDividers lower-color="#ffbc0d" upper-color="#1f6437"/>
    <ParagraphImageHorizontal
        v-if="$store.state.text.modules.paragraphImageHorizontal1"
        :title="$store.state.text.modules.paragraphImageHorizontal1.title"
        :text="$store.state.text.modules.paragraphImageHorizontal1.text"
        :image="parapgraphHorizontal"
        passed-class="pl-16 pr-16 pt-8 pb-12"
    />
    <Gallery :path="text.gallery.path" :no-of-images="text.gallery.noOfImages" background="#A9C141"
             v-if="text.gallery && text.gallery.path && text.gallery.noOfImages" :passed-class="'mt-16'"
             :title="text.gallery.title" :captions="text.gallery.captions"/>
    <v-row class="flex-column">
      <TwoColorDividers lower-color="#ffbc0d" upper-color="#d90007"/>
    </v-row>
    <v-row>
      <iframe src="https://www.md-bilddatenbank.de/start/images/Panorama/1804_KiefRE21/" width="100%" height="600"/>
    </v-row>
    <MapModule/>
    <HorizontalCardModule bg-color="#ffbc0d" passed-class="mt-1 pt-16 pb-16" :data="horizontalCardModule"
                          v-if="horizontalCardModule"/>
    <DoubleCardsModule bg-color="#1f6437" :images="doubleCardImages" :data="text.doubleCardsModule"
                       passed-class="mt-6 pt-16" v-if="text.doubleCardsModule"/>
    <TwoColorDividers upper-color="#a9c141" lower-color="#d90007"/>
  </v-container>
</template>

<script>
import paragraphImage from "@/assets/img/3_McDrive_Instore_FutureConcepts/McD_McDrive_Fullscreenimages_01.jpg";
import parapgraphHorizontal from "@/assets/img/3_McDrive_Instore_FutureConcepts/McD_McDrive_Content_images_01.jpg"
import image1 from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_Instore_Half_Teaser_01.jpg";
import image2 from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_McDrive_Half_Teaser_01.jpg";


import H1Intro from "@/components/common/H1Intro";
import HeroContent from "@/components/common/HeroContent";
import TwoColorDividers from "@/components/common/TwoColorDividers";
import ConversionTeaserBig from "@/components/common/ConversionTeaserBig";
import ParagraphImageVertical from "@/components/common/ParagraphImageVertical";
import ParagraphImageHorizontal from "@/components/common/ParagraphImageHorizontal";
import MapModule from "@/components/MapModule";
import HorizontalCardModule from "@/components/HorizontalCardModule";
import DoubleCardsModule from "@/components/DoubleCardsModule";
import Gallery from "@/components/common/Gallery";

export default {
  name: "McDrivePage",
  components: {
    Gallery,
    DoubleCardsModule,
    HorizontalCardModule,
    MapModule,
    ParagraphImageHorizontal,
    ParagraphImageVertical, ConversionTeaserBig, TwoColorDividers, HeroContent, H1Intro,
  },
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  data: () => ({
    paragraphImage,
    parapgraphHorizontal,
    doubleCardImages: [
      image1,
      image2
    ]
  }),
  computed: {
    horizontalCardModule() {
      return this.$store.state.text.modules.horizontalCardModule;
    },
    text() {
      return this.$store.state.text.modules;
    }
  }
}
</script>

<style scoped>

</style>
