<template>
  <div class="mt-11 contact-page">
    <H1Intro :title="text.h1intro.title" :text="text.h1intro.text" :passed-class="'mt-16 mb-16 pb-6'"/>
    <TwoColorDividers upper-color="#d90007" lower-color="#ffbc0d"/>
    <DynamicCards :cards-data="text.dynamicCards" :images="cardsImages" :border="true"
                  class="px-15"
                  passed-class="pt-10 mt-16 pb-9 mb-16"
                  :card-width="3"
                  passed-text-class="dynamicCards1"
                  :fz="'font-size: 16px;line-height:20px;'"
                  item-text-class="item-text"
                  v-on:scrollTo="scrollTo"/>
    <TwoColorDividers upper-color="#ffbc0d" lower-color="#a9c141"/>
    <div id="contactForm" class="contactForm">
      <ContactForm class="mb-16 pb-16"/>
    </div>
    <TwoColorDividers upper-color="#1f6437" lower-color="#ffbc0d"/>
  </div>
</template>

<script>
import furchert from "@/assets/img/5_Kontakt/MCD_Kontakt_Quart_Teaser_Furchert_01.jpg";
import neumann from "@/assets/img/5_Kontakt/MCD_Kontakt_Quart_Teaser_Neumann_01.jpg";
import weber from "@/assets/img/5_Kontakt/MCD_Kontakt_Quart_Teaser_Weber_01.jpg";

import H1Intro from "@/components/common/H1Intro";
import TwoColorDividers from "@/components/common/TwoColorDividers";
import DynamicCards from "@/components/common/DynamicCards";
import ContactForm from "@/components/common/ContactForm";

export default {
  name: "ContactPage",
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  data: () => ({
    cardsImages: [
      weber, neumann, furchert
    ],
    customSubject: null,
  }),
  components: {ContactForm, DynamicCards, TwoColorDividers, H1Intro},
  computed: {
    text() {
      return this.$store.state.text.kontakt;
    },
  },
  methods: {
    scrollTo(action) {
      this.$store.commit('setCustomSubject', action);
      const el = this.$el.getElementsByClassName('contactForm')[0];
      el.scrollIntoView({behavior: 'smooth'});
    },
  }
}
</script>

<style scoped>
.item-text {
}
</style>

<style>

</style>
