<template>
  <v-row no-gutters justify="center" :class="cssClass"
         :style="{'background-color': background ? background : 'transparent'}">
    <v-img :src="backgroundImage" eager v-if="backgroundImage" width="100%"
    class="position-absolute"
           :style="'z-index: 0;' + bgHeight"/>
    <v-col cols="12" md="10" class="pt-8 max-width-1168" style="z-index: 1">
      <v-row no-gutters justify="center">
        <v-col cols="12" :md="$vuetify.breakpoint.mobile ? 0 : 6" :class="cssTitleColumn">
          <p v-html="title" class="title-size pr-10"/>
        </v-col>
        <v-col cols="12" :md="$vuetify.breakpoint.mobile ? 0 : 6" :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : ''">
          <p v-html="text" :class="textCss" />
          <v-img :src="image" eager contain width="100%" v-if="image"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "ParagraphImageHorizontal",
  props: ['title', 'text', 'image', 'passedClass', 'background', 'textClass', 'backgroundImage', 'titleClass'],
  computed: {
    bgHeight() {
      return this.$vuetify.breakpoint.smAndDown ? 'height: 110%;' : 'height: 490px;'
    },
    cssClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'positon-relative ';
      } else {
        return this.passedClass ? this.passedClass : 'positon-relative pt-16 mt-10'
      }
    },
    textCss() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.textClass ? this.textClass : '';
      } else {
        return 'text-size ' + (this.textClass ? this.textClass : ' pt-10 pb-8 ');
      }
    },
    cssTitleColumn() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'pl-3 '+ (this.titleClass ? this.titleClass : '');
      } else {
        return 'pr-16 pt-10 pl-16 '+ (this.titleClass ? this.titleClass : '');
      }
    }
  }
}
</script>

<style scoped>

</style>
