<template>
  <v-row justify="center">
    <v-col cols="12" v-if="cardData.title" class="text-center pb-5">
      <p v-html="cardData.title" class="d-inline-block title-size white--text"/>
    </v-col>
    <v-col cols="10" :md="$vuetify.breakpoint.mobile ? 0 : 6" v-for="(data, index) in cardData.items"
           :key="'card'+index"
           :class="{
            'pb-5':true,
           }">
      <v-card class="cardClass">
        <v-img :src="images[index]" eager/>
        <v-card-title v-html="data.title" class="pt-9 pl-12 pr-12 pb-3"/>
        <v-card-text class="pl-12 pr-12 pb-16">
          <p v-html="data.text" class="text-size" style="height:80px"/>
          <p>
            <button class="mdButton" v-html="data.button" @click="$router.push({name:data.link})"/>
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DoubleHorizontalCards",
  props: ['cardData', 'images']
}
</script>

<style scoped>
.card-title, .v-card__title {
  font-family: "Speedee Bold";
  color: #292929;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.14px;
  line-height: 33px;
}

.cardClass {
  margin: auto;
  width: 98 %;
}
</style>
