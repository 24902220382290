<template>
  <div>
    <H1Intro :title="aktuelles.h1intro.title" :text="aktuelles.h1intro.text" :passed-class="'mt-16 pt-13 mb-8'" />
    <TwoColorDividers upper-color="#1F6437" lower-color="#FFBC0D" />
    <div v-for="(news, i) in aktuelles.news" :key="i">
      <ParagraphImageHorizontal :title="news.title"
                                :text="news.text"
                                passed-class="pa-16 pt-6 mb-9"
                                :image="news.image"
      />
      <TwoColorDividers upper-color="#D90007" lower-color="#FFBC0D" />
    </div>
    <HorizontalCardModule bg-color="#ffbc0d" passed-class="mt-0 pt-16 pb-16" :data="standorte.horizontalCardModule1" />
        <TwoColorDividers lower-color="#D90007" upper-color="#A9C141" passed-class="pb-10"/>
  </div>
</template>

<script>
import heroImage from "@/assets/img/2_Standorte/MCD_Standorte_Big_Teaser_01.jpg";
import verticalimage from '@/assets/img/2_Standorte/McD_Standorte_Fullscreenimages_01.jpg';
import imageHorizontal2 from '@/assets/img/2_Standorte/McD_Standorte_Content_images_01.jpg';
import card1 from "@/assets/img/0_Global/MCD_McDrive_Third_Teaser_01.jpg";
import card2 from "@/assets/img/0_Global/MCD_Instore_Third_Teaser_01.jpg";
import card3 from "@/assets/img/0_Global/MCD_FutureConcepts_Third_Teaser_01.jpg";
import stamp1 from '@/assets/img/1_Home/Infographik/Mitarbeiter_2023.svg';
import stamp2 from '@/assets/img/1_Home/Infographik/Franchise_2023.svg';
import stamp3 from '@/assets/img/1_Home/Infographik/Restaurants_2023.svg';

import H1Intro from "../common/H1Intro";
import ParagraphImageHorizontal from "../common/ParagraphImageHorizontal";
import TwoColorDividers from "../common/TwoColorDividers";
import HorizontalCardModule from "../HorizontalCardModule";

export default {
  name: "AktuellesPage", metaInfo: function () {
    return {
      title: this.standorte.metaTitle ? this.standorte.metaTitle : '', meta: [{name: 'description', content: this.standorte.metaDescription ? this.standorte.metaDescription : ''}]
    }
  }, components: {
    HorizontalCardModule,
    TwoColorDividers,
    ParagraphImageHorizontal,
    H1Intro
  }, data: () => ({
    heroImage, verticalimage, stamp1, stamp2, stamp3, imageHorizontal2, cardsImages: [card1, card2, card3],
  }), mounted() {
    this.$store.commit('setCustomSubject', null);
  }, computed: {
    standorte() {
      return this.$store.state.text.standorte;
    },
    aktuelles() {
      return this.$store.state.text.aktuelles;
    },
  }
}
</script>

<style scoped>

</style>
