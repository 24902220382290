<template>
  <v-row no-gutters :style="{backgroundColor: bgColor}" justify="center"
         :class="passedClass ? passedClass : 'pt-16 pb-16'">
    <v-col cols="12" class="pt-10 max-width-1168 mb-16 pb-4">
      <DoubleHorizontalCards :card-data="data ? data : cardData" :images="images"/>
    </v-col>
  </v-row>
</template>

<script>
import DoubleHorizontalCards from "@/components/common/DoubleHorizontalCards";

export default {
  name: "DoubleCardsModule",
  components: {DoubleHorizontalCards},
  props: ['bgColor', 'images', 'data', 'passedClass'],
}
</script>

<style scoped>

</style>
