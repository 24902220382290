<template>
  <v-row class="mt-9 pt-11" id="contactForm">
    <v-col cols="12" md="10" offset-md="2">
      <v-col cols="12" md="4">
        <p class="title-size">{{ text.title }}</p>
        <v-form ref="form" v-model="valid" lazy-validation class="mt-12">
          <v-radio-group v-model="form.gender">
            <template v-slot:label>
              <div class="text-size">{{ text.andrede }}</div>
            </template>
            <v-radio :value="'frau'">
              <template v-slot:label>
                <div class="text-size">{{ text.frau }}</div>
              </template>
            </v-radio>
            <v-radio :value="'herr'">
              <template v-slot:label>
                <div class="text-size">{{ text.herr }}</div>
              </template>
            </v-radio>
            <v-radio :value="'divers'">
              <template v-slot:label>
                <div class="text-size">{{ text.divers }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-text-field
            :rules="requiredRules"
            required
            v-model="form.firstName"
          >
            <template slot="label"><span v-html="text.vorname" /></template>
          </v-text-field>

          <v-text-field :rules="requiredRules" required v-model="form.lastName">
            <template slot="label"><span v-html="text.nachname" /></template>
          </v-text-field>

          <v-text-field :rules="requiredRules" required v-model="form.company">
            <template slot="label"><span v-html="text.firma" /></template>
          </v-text-field>

          <v-text-field :rules="emailRules" required v-model="form.email">
            <template slot="label"
              ><span v-html="text.Emailaddresse"
            /></template>
          </v-text-field>

          <v-text-field :rules="requiredRules" required v-model="form.phone">
            <template slot="label"
              ><span v-html="text.Telefonnummer"
            /></template>
          </v-text-field>

          <v-select
            v-model="form.subject"
            :items="subject"
            label="Standard"
            :rules="requiredRules"
          >
            <template slot="label"><span v-html="text.subject" /></template>
          </v-select>

          <v-textarea
            :rules="requiredRules"
            name="input-7-1"
            :counter="1200"
            v-model="form.message"
          >
            <template slot="label"><span v-html="text.message" /></template>
          </v-textarea>

          <v-file-input
            v-model="form.fileupload"
            accept="*"
            :label="text.fileupload"
            prepend-icon=""
            :append-icon="'mdi-plus'"
            :rules="fileuploadRules"
            clearable
          ></v-file-input>

          <p v-html="text.acceptInfo" />
          <v-checkbox :rules="acceptRules" v-model="form.tcAccepted">
            <template v-slot:label>
              <div v-html="text.checkboxLabel" @click.stop />
            </template>
          </v-checkbox>

          <p><small v-html="text.smallinfo" /></p>

          <v-col cols="12" md="6">
            <v-row no-gutters class="align-center mb-5">
              <v-col cols="3">
                <v-img :src="captcha" />
              </v-col>
              <v-col cols="9" >
                <v-text-field
                  v-model="captchaValidation"
                  :rules="captchaRules"
                  required
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <button
            class="mdButton"
            v-html="text.submit"
            @click.prevent="submit"
          />
        </v-form>
      </v-col>
    </v-col>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="showConfirmation"
    >
      <v-card>
        <v-card-title />

        <v-card-text class="confirm-message" v-html="text.confirmSend" />
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import captcha from "@/assets/img/0_Global/captcha.jpg";

export default {
  name: "ContactForm",
  data: () => ({
    form: {
      gender: null,
      firstName: null,
      lastName: null,
      company: null,
      email: null,
      phone: null,
      tcAccepted: null,
      message: null,
      fileupload: null,
      subject: null,
    },
    captcha,
    valid: true,
    showConfirmation: false,
    captchaValidation: null,
  }),
  methods: {
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      const formData = new FormData();
      formData.append("firstName", this.form.firstName);
      formData.append("lastName", this.form.lastName);
      formData.append("company", this.form.company);
      formData.append("email", this.form.email);
      formData.append("phone", this.form.phone);
      formData.append("tcAccepted", this.form.tcAccepted);
      formData.append("message", this.form.message);
      formData.append("file", this.form.fileupload);
      formData.append("subject", this.form.subject);
      formData.append("salutation", this.form.gender);

      this.axios
        .post(process.env.VUE_APP_MAIL_ENDPOINT, formData)
        .then((response) => {
          if (response.data.success === "Ok") {
            this.showConfirmation = true;
            this.emptyFormFields();
          } else {
            this.success = false;
            this.showConfirmation = true;
            this.emptyFormFields();
          }
        })
        .catch(() => {
          console.log("error");
        });
    },
    emptyFormFields(){
      this.$refs.form.reset()
    }
  },
  computed: {
    subject() {
      const subjects = [...this.$store.state.text.kontakt.form.emailSubjects];
      if (this.$store.state.customSubject !== null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.subject = this.$store.state.customSubject;
        subjects.push(this.$store.state.customSubject);
      }
      return subjects;
    },
    text() {
      return this.$store.state.text.kontakt.form;
    },
    acceptRules: {
      get: function () {
        return [(v) => !!v || this.text.haveToAccept];
      },
    },
    captchaRules: {
      get: function () {
        return [
          (v) => !!v || this.text.fieldRequired,
          (v) => v == 5 || this.text.correctResult,
        ];
      },
    },
    fileuploadRules: {
      get: function () {
        return [(v) => !v || v.size <= 3000000 || this.text.fileUploadRuses] ;
      },
    },
    requiredRules: {
      get: function () {
        return [(v) => !!v || this.text.fieldRequired];
      },
    },
    emailRules: {
      get: function () {
        return [
          (v) => !!v || this.text.fieldRequired,
          (v) => /.+@.+\..+/.test(v) || this.text.validEmail,
        ];
      },
    },
  },
};
</script>

<style scoped>
.title-size {
  font-size: 28px;
}

.confirm-message  {
  text-align: center;
}
</style>
