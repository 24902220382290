<template>
  <div class="mt-11">
    <H1Intro v-if="text.h1intro" :title="text.h1intro.title" :text="text.h1intro.text" :passed-class="'mt-16'"/>
    <HeroContent v-if="text.heroContent"
                 :title="text.heroContent.title"
                 :text="text.heroContent.text"
                 position="right"
                 :passed-class="'pt-5 pb-11'"
                 :image="heroImage"/>
    <TwoColorDividers upper-color="#D90007" lower-color="#FFBC0D" :switched="true" passed-class="mt-16"/>
    <ParagraphImageHorizontal v-if="text.paragraphImageHorizontal"
                              :title="text.paragraphImageHorizontal.title"
                              :text="text.paragraphImageHorizontal.text"
                              :background-image="woodenBackground"
                              text-class="white--text mt-16 pt-4"
                              title-class="white--text mt-10"
                              :passed-class="{
                                'pt-0 overflow-hidden':true,
                                'pl-2 pr-2 overflow-hidden': $vuetify.breakpoint.mobile,
                              }"
    />
    <ThreeStamps :stamp1="stamp1" :stamp2="stamp2" :stamp3="stamp3" :title="text.threeStamps.title"
                 :passedClass="'mb-16 mt-16 pb-4 '" v-if="text.threeStamps"
                 column-class="mt-16 pt-3"
    />
    <ParagraphImageVertical :image="verticalimage"/>
    <TwoColorDividers upper-color="#FFBC0D" lower-color="#A9C141"/>
    <ParagraphImageHorizontal :title="text.paragraphImageHorizontal1.title"
                              :text="text.paragraphImageHorizontal1.text"
                              :image="vertical1"
                              :passed-class="{
                                'pt-9 pb-9':true,
                                'pr-2 pl-2': $vuetify.breakpoint.mobile
                              }"
                              v-if="text.paragraphImageHorizontal1"
                              text-class="pt-10 pb-10"
    />
    <ParagraphImageHorizontal :title="text.paragraphImageHorizontal2.title"
                              :text="text.paragraphImageHorizontal2.text"
                              :image="vertical2"
                              background="#A9C141"
                              :passed-class="{
                                'pt-0 pb-12': true,
                                'pr-2 pl-2': $vuetify.breakpoint.mobile
                              }"
                              v-if="text.paragraphImageHorizontal2"
                              text-class="pt-10 pb-14"
    />
    <TwoColorDividers upper-color="#1F6437" lower-color="#FFBC0D"/>
    <ParagraphImageHorizontal :title="text.paragraphImageHorizontal3.title"
                              :text="text.paragraphImageHorizontal3.text"
                              :image="vertical3"
                              :passed-class="{
                                'pt-5 pb-16 mb-11':true,
                                'pr-2 pl-2': $vuetify.breakpoint.mobile
                              }"
                              text-class="pb-14 pt-10"
                              v-if="text.paragraphImageHorizontal3"/>
    <TwoColorDividers upper-color="#1F6437" lower-color="#D90007" :switched="true"/>
    <HorizontalCardModule bg-color="#ffbc0d" passed-class="pt-16 pb-16" :data="text.horizontalCardModule"
                          v-if="text.horizontalCardModule"/>
    <TwoColorDividers upper-color="#D90007" lower-color="#ffbc0d"/>
    <DynamicCards v-if="text.threeCards" :cards-data="text.threeCards" :images="cardsImages" :border="true"
                  :passed-class="commonPadding+' mt-16 mb-10'" height="450" passed-text-class="dynamicCards1"/>
    <TwoColorDividers upper-color="#A9C141" lower-color="#D90007" passed-class="mb-16"/>
  </div>
</template>

<script>
import woodenBackground from "@/assets/img/4_Unternehmen/MCD_Background_Unternehmen_Wood.jpg";
import heroImage from "@/assets/img/4_Unternehmen/MCD_Unternehmen_Big_Teaser_01.jpg";
import stamp1 from '@/assets/img/1_Home/Infographik/Mitarbeiter_2023.svg';
import stamp2 from '@/assets/img/1_Home/Infographik/Franchise_2023.svg';
import stamp3 from '@/assets/img/1_Home/Infographik/Restaurants_2023.svg';
import verticalimage from '@/assets/img/4_Unternehmen/McD_Unternehmen_Fullscreenimages_01.jpg';
import vertical1 from '@/assets/img/4_Unternehmen/McD_Unternehmen_Content_images_01.jpg';
import vertical2 from '@/assets/img/4_Unternehmen/McD_Unternehmen_Content_images_02.jpg';
import vertical3 from '@/assets/img/4_Unternehmen/McD_Unternehmen_Content_images_03.jpg';
import card1 from "@/assets/img/0_Global/MCD_McDrive_Third_Teaser_01.jpg";
import card2 from "@/assets/img/0_Global/MCD_Instore_Third_Teaser_01.jpg";
import card3 from "@/assets/img/0_Global/MCD_FutureConcepts_Third_Teaser_01.jpg";


import H1Intro from "../common/H1Intro";
import HeroContent from "../common/HeroContent";
import TwoColorDividers from "../common/TwoColorDividers";
import ParagraphImageHorizontal from "../common/ParagraphImageHorizontal";
import ThreeStamps from "../common/ThreeStamps";
import ParagraphImageVertical from "../common/ParagraphImageVertical";
import HorizontalCardModule from "../HorizontalCardModule";
import DynamicCards from "../common/DynamicCards";

export default {
  name: "UnternehmenPage",
  metaInfo: function () {
    return {
      title: this.text.metaTitle ? this.text.metaTitle : '',
      meta: [
        {name: 'description', content: this.text.metaDescription ? this.text.metaDescription : ''}
      ]
    }
  },
  components: {
    DynamicCards,
    HorizontalCardModule,
    ParagraphImageVertical, ThreeStamps, ParagraphImageHorizontal, TwoColorDividers, HeroContent, H1Intro
  },
  data: () => ({
    woodenBackground,
    heroImage,
    commonPadding: 'pt-16 pb-16',
    topPadding: 'pt-16',
    bottomPatting: 'pb-16',
    stamp1,
    stamp2,
    stamp3,
    verticalimage,
    vertical1,
    vertical2,
    vertical3,
    cardsImages: [
      card1,
      card2,
      card3
    ],
  }),
  mounted() {
    this.$store.commit('setCustomSubject', null);
  },
  computed: {
    text() {
      return this.$store.state.text.unternehmen;
    },
  }
}
</script>

<style scoped>

</style>
