var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-11"},[(_vm.text.h1intro)?_c('H1Intro',{attrs:{"title":_vm.text.h1intro.title,"text":_vm.text.h1intro.text,"passed-class":'mt-16'}}):_vm._e(),(_vm.text.heroContent)?_c('HeroContent',{attrs:{"title":_vm.text.heroContent.title,"text":_vm.text.heroContent.text,"position":"right","passed-class":'pt-5 pb-11',"image":_vm.heroImage}}):_vm._e(),_c('TwoColorDividers',{attrs:{"upper-color":"#D90007","lower-color":"#FFBC0D","switched":true,"passed-class":"mt-16"}}),(_vm.text.paragraphImageHorizontal)?_c('ParagraphImageHorizontal',{attrs:{"title":_vm.text.paragraphImageHorizontal.title,"text":_vm.text.paragraphImageHorizontal.text,"background-image":_vm.woodenBackground,"text-class":"white--text mt-16 pt-4","title-class":"white--text mt-10","passed-class":{
                              'pt-0 overflow-hidden':true,
                              'pl-2 pr-2 overflow-hidden': _vm.$vuetify.breakpoint.mobile,
                            }}}):_vm._e(),(_vm.text.threeStamps)?_c('ThreeStamps',{attrs:{"stamp1":_vm.stamp1,"stamp2":_vm.stamp2,"stamp3":_vm.stamp3,"title":_vm.text.threeStamps.title,"passedClass":'mb-16 mt-16 pb-4 ',"column-class":"mt-16 pt-3"}}):_vm._e(),_c('ParagraphImageVertical',{attrs:{"image":_vm.verticalimage}}),_c('TwoColorDividers',{attrs:{"upper-color":"#FFBC0D","lower-color":"#A9C141"}}),(_vm.text.paragraphImageHorizontal1)?_c('ParagraphImageHorizontal',{attrs:{"title":_vm.text.paragraphImageHorizontal1.title,"text":_vm.text.paragraphImageHorizontal1.text,"image":_vm.vertical1,"passed-class":{
                              'pt-9 pb-9':true,
                              'pr-2 pl-2': _vm.$vuetify.breakpoint.mobile
                            },"text-class":"pt-10 pb-10"}}):_vm._e(),(_vm.text.paragraphImageHorizontal2)?_c('ParagraphImageHorizontal',{attrs:{"title":_vm.text.paragraphImageHorizontal2.title,"text":_vm.text.paragraphImageHorizontal2.text,"image":_vm.vertical2,"background":"#A9C141","passed-class":{
                              'pt-0 pb-12': true,
                              'pr-2 pl-2': _vm.$vuetify.breakpoint.mobile
                            },"text-class":"pt-10 pb-14"}}):_vm._e(),_c('TwoColorDividers',{attrs:{"upper-color":"#1F6437","lower-color":"#FFBC0D"}}),(_vm.text.paragraphImageHorizontal3)?_c('ParagraphImageHorizontal',{attrs:{"title":_vm.text.paragraphImageHorizontal3.title,"text":_vm.text.paragraphImageHorizontal3.text,"image":_vm.vertical3,"passed-class":{
                              'pt-5 pb-16 mb-11':true,
                              'pr-2 pl-2': _vm.$vuetify.breakpoint.mobile
                            },"text-class":"pb-14 pt-10"}}):_vm._e(),_c('TwoColorDividers',{attrs:{"upper-color":"#1F6437","lower-color":"#D90007","switched":true}}),(_vm.text.horizontalCardModule)?_c('HorizontalCardModule',{attrs:{"bg-color":"#ffbc0d","passed-class":"pt-16 pb-16","data":_vm.text.horizontalCardModule}}):_vm._e(),_c('TwoColorDividers',{attrs:{"upper-color":"#D90007","lower-color":"#ffbc0d"}}),(_vm.text.threeCards)?_c('DynamicCards',{attrs:{"cards-data":_vm.text.threeCards,"images":_vm.cardsImages,"border":true,"passed-class":_vm.commonPadding+' mt-16 mb-10',"height":"450","passed-text-class":"dynamicCards1"}}):_vm._e(),_c('TwoColorDividers',{attrs:{"upper-color":"#A9C141","lower-color":"#D90007","passed-class":"mb-16"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }