<template>
  <v-row no-gutters justify="center" :class="passedClass ? passedClass : ''">
    <v-col cols="12" class="max-width-1168" v-show="oneTrustGoogleMapsAcceptedByUser">
      <div
          style="min-height:600px;"
          data-key="nitFiP4IOe4LbUzYL8ULuD99IHoTbh"
          id="store-finder-widget"
      ></div>
    </v-col>

    <v-col cols="12" class="max-width-1168" v-if="!oneTrustGoogleMapsAcceptedByUser">
      <div class="standorte-fallback">
        <p>
          Um dir die Kartendienste des Drittanbieters Google Maps anzeigen zu können, benötigen wir deine Einwilligung.
          Beim Laden oder Abspielen wird eine Verbindung zu den Servern des Anbieters hergestellt.
          Dabei können dem Anbieter personenbezogene Daten mitgeteilt werden. Mit Klick auf die Schaltfläche
          „Inhalte laden“ willigst du in diese Verarbeitung ein.
          Deine Einwilligung ist jederzeit im
          <a @click.prevent="showOneTrust">Cookie-Präferenzcenter</a> frei widerruflich.
          Weitere Informationen findest du in der
          <a href="https://www.mcdonalds.com/content/dam/sites/de/unreferenced/Datenschutz/DatenschutzinformationenMcDonaldsRealEstate_20230329.pdf" target="_blank">
            Datenschutzerklärung
          </a>.
        </p>

        <button :class="{ 'mdButton':true}"  @click="acceptGoogleMaps">
          Inhalte laden
        </button>
      </div>
    </v-col>
  </v-row>
</template>

<script>

import addActiveGroups from "@/helper/onetrust-single-script.js"

export default {
  name: "ActualMap",
  props: ['passedClass'],
  data: () => ({
    oneTrustGoogleMapsAcceptedByUser: false
  }),
  mounted() {
    this.loadGoogleMaps();
  },
  methods:{
    acceptGoogleMaps() {
      addActiveGroups('7');
      // window.location.reload();
      this.loadGoogleMaps();
    },
    showOneTrust() {
      window.OneTrust.ToggleInfoDisplay();
    },
    loadGoogleMaps() {
      this.oneTrustGoogleMapsAcceptedByUser = window.OnetrustActiveGroups.includes('7');
      const script = document.createElement("script");
      script.src = "https://locator.uberall.com/locator-assets/storeFinderWidget-v2.js";
      script.type = "text/javascript";
      if(this.oneTrustGoogleMapsAcceptedByUser) {
        document.body.appendChild(script);
      }

      script.onload = () => {
        window.document.dispatchEvent(new Event("DOMContentLoaded", {
          bubbles: true,
          cancelable: true
        }));
      };
    }
  }
}
</script>

<style scoped>
iframe {
  overflow: hidden;
}

.standorte-fallback {
  padding: 20px 40px;
  text-align: center;
}
</style>
