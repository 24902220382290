<template>
  <v-row no-gutters justify="center" :class="rowCss">
    <v-col cols="12" md="10">
      <ParagraphImageVertical :image="image" :title="title" :text="text"/>
    </v-col>
  </v-row>
</template>

<script>
import ParagraphImageVertical from "@/components/common/ParagraphImageVertical";
import image from "@/assets/img/3_McDrive_Instore_FutureConcepts/MCD_McDrive_Fieldmap_2023_cropped.jpg";

export default {
  name: "MapModule",
  components: {ParagraphImageVertical},
  data: () => ({
    image
  }),
  computed: {
    rowCss() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'pt-16 mt-10 pb-16 mb-10 pr-2 pl-2';
      } else {
        return 'pt-16 mt-9 pb-13 mb-10';
      }
    },
    title() {
      return this.$store.state.text.modules.mapModule.title;
    },
    text() {
      return this.$store.state.text.modules.mapModule.text;
    },
  }
}
</script>

<style scoped>

</style>
